
import { defineComponent, onMounted } from "vue";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTListWidget5 from "@/components/widgets/lists/Widget5.vue";
import KTTableWidget5 from "@/components/widgets/tables/Widget5.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditSocialInformationModal from "@/components/modals/forms/EditSocialInformationModal.vue";

export default defineComponent({
  name: "account-billing",
  components: { EditSocialInformationModal },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Plan", ["Account"]);
    });
  },
});
