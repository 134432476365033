
import { defineComponent, ref, toRef } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { Account } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "edit-social-information-modal",
  components: {},
  props: ["account", "location"],

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const editSocialInformationRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const targetData = toRef(props, "account");

    const rules = ref({});

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          ApiService.update("api/v1/accounts", props.account.id, {
            name: targetData.value.name,
            websiteUrl: targetData.value.websiteUrl,
            facebookUrl: targetData.value.facebookUrl,
            instagramUrl: targetData.value.instagramUrl,
            twitterUrl: targetData.value.twitterUrl,
          })
            .then(() => {
              store.dispatch(Actions.REFRESH_ACCOUNT);
              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editSocialInformationRef.value);
              });
            })
            .finally(() => {
              loading.value = false;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      targetData,
      submit,
      loading,
      formRef,
      rules,
      editSocialInformationRef,
    };
  },
});
